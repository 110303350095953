'use client';
import Image from 'next/image';
import React, { useState, useEffect, useRef } from 'react';

import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import useThemeStore from '@/app/stores/useThemeStore';

import whatsappIcon from '@/public/searchPage/whatsapp.svg';
import copyLinkIcon from '@/public/searchPage/link.svg';
import facebookIcon from '@/public/searchPage/facebook.svg';
import twitterIcon from '@/public/searchPage/twitter.svg';
import linkedinIcon from '@/public/searchPage/linkedin.svg';
import { ShareIcon } from '@/components/SvgFiles';

interface ShareOption {
  title: string;
  icon: string;
}

const shareOptions: ShareOption[] = [
  { title: 'Share on Whatsapp', icon: whatsappIcon },
  { title: 'Copy link', icon: copyLinkIcon },
  { title: 'Share on Facebook', icon: facebookIcon },
  { title: 'Share on Twitter', icon: twitterIcon },
  { title: 'Share on LinkedIn', icon: linkedinIcon },
];

function SocialShare({ title, url }: any): JSX.Element {
  const [sharePopup, setSharePopup] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const { setCopyUrlToast } = useThemeStore((state) => state);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setSharePopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const shareUrl = `${process.env.NEXT_PUBLIC_APP_URL}/${url}`;

  const handleSharePopup = (e: any) => {
    e.preventDefault();
    navigator.clipboard.writeText(shareUrl);
    setSharePopup(false);
    setCopyUrlToast(true);
    setTimeout(() => {
      setCopyUrlToast(false);
    }, 1000);
  };

  return (
    <>
      {/* <ToastContainer /> */}
      {/* <Image
        className='w-aut0 ml-auto h-auto cursor-pointer'
        alt='social share button'
        src={shareIcon}
        onClick={(e) => {
          e.stopPropagation();
          setSharePopup((prev) => !prev);
        }}
        /> */}
      <button
        className={'ml-auto h-auto w-auto cursor-pointer pl-[20px]'}
        onClick={(e) => {
          e.preventDefault();
          setSharePopup((prev) => !prev);
        }}
      >
        <ShareIcon
          className={'ml-auto h-auto w-auto cursor-pointer pl-[20px]'}
          color={'fill-[#1c2534] dark:fill-[#9ea8ac]'}
        />
        <span className='sr-only'>Share button </span>
      </button>

      {sharePopup && (
        <section
          onMouseLeave={() => setSharePopup(false)}
          ref={popupRef}
          className='absolute right-0 top-[40px] z-[5] w-[200px] rounded-[10px] border border-gray-300 bg-white  px-[10px] text-[14px] shadow-xl'
        >
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            className='my-[15px] flex gap-[10px] rounded-lg p-[5px] hover:bg-slate-100'
          >
            <Image src={shareOptions[0].icon} alt='' />
            {shareOptions[0].title}
          </WhatsappShareButton>

          <section
            className='my-[15px] flex gap-[10px] rounded-lg p-[5px] hover:bg-slate-100'
            onClick={handleSharePopup}
          >
            <Image src={shareOptions[1].icon} alt='' />
            {shareOptions[1].title}
          </section>

          <FacebookShareButton
            url={shareUrl}
            quote={title}
            hashtag='#karepedia'
            className='my-[15px] flex gap-[10px] rounded-lg p-[5px] hover:bg-slate-100'
          >
            <Image src={shareOptions[2].icon} alt='' />
            {shareOptions[2].title}
          </FacebookShareButton>

          <TwitterShareButton
            url={shareUrl}
            title={title}
            hashtags={['#karepedia']}
            className='my-[15px] flex gap-[10px] rounded-lg p-[5px] hover:bg-slate-100'
          >
            <Image src={shareOptions[3].icon} alt='' />
            {shareOptions[3].title}
          </TwitterShareButton>

          <LinkedinShareButton
            url={shareUrl}
            title={title}
            className='my-[15px] flex gap-[10px] rounded-lg p-[5px] hover:bg-slate-100'
          >
            <Image src={shareOptions[4].icon} alt='' />
            {shareOptions[4].title}
          </LinkedinShareButton>
        </section>
      )}
    </>
  );
}

export default SocialShare;
