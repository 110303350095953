'use client';
import React from 'react';
import useThemeStore from '../app/stores/useThemeStore';

export const HeadingText = (props: any) => {
  const { font } = useThemeStore((state) => state);
  const { heading } = font;
  return (
    <h2 className={`${props?.className} ${heading}  `}>{props.children}</h2>
  );
};
export const TitleText = (props: any) => {
  const { font } = useThemeStore((state) => state);
  const { title } = font;
  return <h2 className={`${props?.className} ${title} `}>{props.children}</h2>;
};
export const Body = (props: any) => {
  const { font } = useThemeStore((state) => state);
  const { body } = font;
  return <p className={`${props?.className} ${body}   `}>{props.children}</p>;
};
export const BlogBody = (props: any) => {
  const { font } = useThemeStore((state) => state);
  const { blogBody } = font;
  return (
    <p className={`${props?.className} ${blogBody}   `}>{props.children}</p>
  );
};
export const BodySmall = (props: any) => {
  const { font } = useThemeStore((state) => state);
  const { bodySmall } = font;
  return (
    <p className={`${props?.className} ${bodySmall}  `}>{props.children}</p>
  );
};
export const FootNote = (props: any) => {
  const { font } = useThemeStore((state) => state);
  const { footNote } = font;
  return <p className={`${props?.className} ${footNote} `}>{props.children}</p>;
};
